import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

export default function Header() {
  return (
    <header>
      <Link to="/">
        <StaticImage style={{display: null}} className="banner" src="../images/banner.png" alt="banner" />
        <StaticImage style={{display: null}} className="logo" src="../images/logo.jpg" alt="logo" />
      </Link>
    </header>
  );
};
