import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby';

const Navigation = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: {frontmatter: {slug: {ne: "home"}}}) {
        edges {
          node {
            frontmatter {
              title
              slug
            }
          }
        }
      }
    }`);

  const pages = data.allMarkdownRemark.edges.map(({ node }) => {
    return {
      title: node.frontmatter.title,
      slug: node.frontmatter.slug,
    }
  });

  const menuRef = React.useRef();

  const toggleMenu = () => {
    if(menuRef.current) {
      const display = menuRef.current.style.display;
      menuRef.current.style.display = display ? null : 'block';
    }
  }

  return (
    <nav>
      <button id="menu" onClick={toggleMenu}>Menu</button>
      <ul ref={menuRef}>
        {
          pages.map(page => {
            return (
              <li key={page.slug}>
                <Link to={`/${page.slug}`}>{ page.title }</Link>
              </li>
            );
          }) 
        }
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  )
}

export default Navigation;
