import * as React from "react"
import { graphql } from 'gatsby';
import Layout from '../components/Layout';

const Page = ({ data }) => {
  return (
    <Layout>
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query($id: String) {
    markdownRemark(id: {eq: $id}) {
      id
      html
    }
  }
`;
